import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  Link,
  Typography
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ShopIcon from '@material-ui/icons/Shop';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import TuneIcon from '@material-ui/icons/Tune';
import useAuth from '../../hooks/useAuth';
import BriefcaseIcon from '../../icons/Briefcase';
import CalendarIcon from '../../icons/Calendar';
import ChartPieIcon from '../../icons/ChartPie';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import ChatAltIcon from '../../icons/ChatAlt';
import ClipboardListIcon from '../../icons/ClipboardList';
import FolderOpenIcon from '../../icons/FolderOpen';
import MailIcon from '../../icons/Mail';
import ShareIcon from '../../icons/Share';
import ShoppingBagIcon from '../../icons/ShoppingBag';
import ShoppingCartIcon from '../../icons/ShoppingCart';
import UserIcon from '../../icons/User';
import UsersIcon from '../../icons/Users';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';

const sections = [
  // {
  //   title: '',
  //   items: [
  //     {
  //       title: 'Products',
  //       path: '/dashboard/products',
  //       icon: <ShoppingCartIcon fontSize="small" />
  //       // children: [
  //       //   {
  //       //     title: 'List',
  //       //     path: '/dashboard/products'
  //       //   }
  //       //   {
  //       //     title: 'Create',
  //       //     path: '/dashboard/products/new'
  //       //   }
  //       // ]
  //     }
  //   ]
  // },
  {
    title: '',
    items: [
      {
        title: 'Orders',
        icon: <FolderOpenIcon fontSize="small" />,
        path: '/dashboard/orders',
        children: [
          {
            title: 'List',
            path: '/dashboard/orders'
          },
          {
            title: 'Details',
            path: '/dashboard/orders/1'
          }

          // {
          //   title: 'Invoices',
          //   path: '/dashboard/orders/invoices'
          // }
        ]
      }
    ]
  },
  {
    title: '',
    items: [
      {
        title: 'Invoices',
        icon: <ReceiptIcon fontSize="small" />,
        path: '/dashboard/invoices',
        children: [
          {
            title: 'List',
            path: '/dashboard/invoices'
          },
          {
            title: 'Details',
            path: '/dashboard/invoices/1'
          }
        ]
      }
    ]
  },
  {
    title: '',
    items: [
      {
        title: 'Inventory',
        icon: <AccountBalanceIcon fontSize="small" />,
        path: '/dashboard/invetory'
      }
    ]
  },
  {
    title: '',
    items: [
      {
        title: 'Webshops',
        icon: <ShopIcon fontSize="small" />,
        path: '/webshops',
        children: [
          {
            title: 'Webshops',
            path: '/dashboard/webshops'
          },
          {
            title: 'Products',
            path: '/dashboard/products'
          }
        ]
      }
    ]
  },
  {
    title: '',
    items: [
      {
        title: 'Configuration',
        icon: <TuneIcon fontSize="small" />,
        path: '/dashboard/configuration'
      }
    ]
  },
  {
    title: '',
    items: [
      {
        title: 'Subscription',
        icon: <AssignmentIcon fontSize="small" />,
        path: '/dashboard/subscription'
      }
    ]
  }
];

const DashboardSidebar = (props) => {
  const { t, i18n } = useTranslation();

  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box display={{ xs: 'flex', lg: 'none' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40
                }}
              />
            </RouterLink>
          </Box>
        </Box>
        {/* <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {user.name}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                Your plan:{' '}
                <Link color="primary" component={RouterLink} to="/pricing">
                  {user.plan}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box> */}
        {/* <Divider /> */}
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  // mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
        {/* <Divider />
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            Need Help?
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Check our docs
          </Typography>
          <Button
            color="primary"
            component={RouterLink}
            fullWidth
            sx={{ mt: 2 }}
            to="/docs"
            variant="contained"
          >
            Documentation
          </Button>
        </Box> */}
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Box display={{ xs: 'flex', lg: 'none' }}>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Box>
      <Box display={{ xs: 'none', lg: 'flex' }}>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Box>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
