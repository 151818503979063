import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Chip,
  Divider,
  Hidden,
  IconButton,
  Link,
  Toolbar
} from '@material-ui/core';
import useAuth from '../hooks/useAuth';

import MenuIcon from '../icons/Menu';
import Logo from './Logo';

const MainNavbar = (props) => {
  const navigate = useNavigate();
  const { onSidebarMobileOpen } = props;
  const { user, isAuthenticated, logout } = useAuth();

  const handleLogout = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      await axios.get(`/login/v1/logout/${accessToken}`);
      await logout();
      navigate('/');
    } catch (err) {
      console.log('Logout Error on MainNavbar');
    }
  };

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.secondary'
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <Box display={{ xs: 'flex', md: 'none' }}>
          <IconButton color="inherit" onClick={onSidebarMobileOpen}>
            <MenuIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box display={{ xs: 'none', md: 'flex' }}>
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          display={{ xs: 'none', md: 'flex' }}
          style={{ alignItems: 'center' }}
        >
          <Link
            color="textSecondary"
            component={RouterLink}
            to="/dashboard"
            underline="none"
            variant="body1"
          >
            Dashboard
          </Link>
          {/* <Chip
            color="primary"
            label="NEW"
            size="small"
            sx={{
              maxHeight: 20,
              ml: 1,
              mr: 2
            }}
          /> */}
          {/* <Link
            color="textSecondary"
            component={RouterLink}
            to="/docs"
            underline="none"
            variant="body1"
          >
            Documentation
          </Link> */}
          <Divider
            orientation="vertical"
            sx={{
              height: 32,
              mx: 2
            }}
          />
          {isAuthenticated ? (
            <Button color="primary" variant="outlined" onClick={handleLogout}>
              Logout
            </Button>
          ) : (
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              Log In
            </Button>
          )}

          {/* <Button
            color="primary"
            component="a"
            href="/dashboard"
            size="small"
            variant="contained"
          >
            Register
          </Button> */}
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default MainNavbar;
