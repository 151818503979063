export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
};

export const SHOP_LIST = [
  'TestUK',
  'LamDE',
  'LamDK',
  'NordicDK',
  'NordicDE',
  'NordicSE',
  'NordicUK',
  'FurDK',
  'NCEU',
  'NCDE',
  'NCDK',
  'BILKA',
  'WUPTI'
];
