import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      'Product List': 'Product List',
      Products: 'Products',
      Shop: 'Shop',
      GTIN: 'GTIN',
      Quantity: 'Quantity',
      Style: 'Style',
      Color: 'Color',
      Size: 'Size',
      SKU: 'SKU',
      Status: 'Status',
      'Order List': 'Order List',
      'Order Number': 'Order Number',
      'Buyer Name': 'Buyer Name',
      'Buyer City': 'Buyer City',
      'Buyer Country': 'Buyer Country',
      OrderBC: 'Order BC',
      DEL: 'DEL',
      DSP: 'DSP',
      INV: 'INV',
      MTC: 'MTC',
      PRN: 'PRN',
      'Invoice List': 'Invoice List'
    }
  },
  dk: {
    translation: {
      'Product List': 'Produkt Liste',
      Products: 'Produkter',
      Shop: 'Butik',
      GTIN: 'GTIN',
      Quantity: 'Antal',
      Style: 'Style',
      Color: 'Farve',
      Size: 'Størrelse',
      SKU: 'SKU',
      Status: 'Status',
      'Order List': 'Order List',
      'Order Number': 'Order Number',
      'Buyer Name': 'Buyer Name',
      'Buyer City': 'Buyer City',
      'Buyer Country': 'Buyer Country',
      OrderBC: 'Order BC',
      DEL: 'DEL',
      DSP: 'DSP',
      INV: 'INV',
      MTC: 'MTC',
      PRN: 'PRN'
    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
});
